import React from 'react';

import './Skills.css';
import images from '../../Assets/images/images'

export default function Skills() {

    type Skill = {
        level: number
    }

    type Skills = {
        [key: string]: Skill
    }

    const skills:Skills = {
        "TypeScript" : {
            "level" : 70
        },
        "JavaScript" : {
            "level" : 65
        },
        "ReactNative" : {
            "level" : 75
        },
        "React" : {
            "level" : 70
        },
        "Node.js" : {
            "level" : 65
        },
        "CSS" : {
            "level" : 70
        },
        "Python" : {
            "level" : 40
        },
        "git" : {
            "level" : 60
        }
    }

    return (
        <div className='skills-section'>
            <h1 className="title">Skills</h1>
            <div className='skills-container'>
                {Object.keys(skills).map((skill, index) => {
                    return (
                        <div key={index} className='skill-box'>
                            {/* <img className='skill-image' src={images[skill]} alt={skill} /> */}
                            <p className='skill-name'>{skill}</p>
                            {/* <p className='skill-level'>Skill Level:</p> */}
                            <div className='skill-level-bar'>
                                <div className='skill-level-bar-inside' style={{width:`${skills[skill].level}%`}}/>
                            </div></div>
                    )
                })}
            </div>
        </div>
    );
}

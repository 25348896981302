import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export default function Navbar({ id }: { id: string }) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

    const menuItems = [
        { label: 'Home', target: 'Header' },
        { label: 'About', target: 'AboutMe' },
        { label: 'Projects', target: 'Projects' },
        { label: 'Hackathons', target: 'Hackathons' },
    ];

    // Scroll to the specified section smoothly
    const scrollToSection = (targetId: string) => {
        const element = document.getElementById(targetId);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
    };

    // Handle window resizing to adjust menu display
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Render the navigation buttons
    const renderMenuItems = () =>
        menuItems.map(({ label, target }) => (
            <button key={target} className='button' onClick={() => scrollToSection(target)}>
                {label}
            </button>
        ));

    return (
        <div className='Navbar-container' id={id}>
            {screenWidth > 768 ? (
                // Full navigation bar for larger screens
                <div className='nav-buttons'>{renderMenuItems()}</div>
            ) : (
                // Mobile navbar with burger menu
                <>
                    <div className='small-navbar-container'>
                        <span className='name'>Svetoslav Iliev</span>
                        <FontAwesomeIcon
                            icon={faBars}
                            color="white"
                            onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
                        />
                    </div>
                    <div className={`burger-menu ${isBurgerMenuOpen ? 'active' : ''}`}>
                        {renderMenuItems()}
                    </div>
                </>
            )}
        </div>
    );
}

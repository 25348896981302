import React from 'react';

import './AboutMe.css';
import Skills from '../../components/Skills';

export default function AboutMe({ id }: { id: string }) {
    return (
        <section className='AboutMe--container' id={id}>
            <h2 className='title'>About Me</h2>
            <div className='information'>
                <img className='image' src='http://findmatthew.com/img/me.png' alt='personal' />
                <div className='about'>
                    <h4 className='title'>Who am I?</h4>
                    <p className='text'>
                        Hi, my name is Svetoslav Iliev, and I am a 16-year-old computer science student from Sofia. Im a student at TUES.
                        I am a full-stack web developer, and I am passionate about creating websites and web applications.
                        I have been programming for 5 years, and I have been working with web development for 1 year.
                        I am currently working on a few projects, and I am looking for a job as a web developer.
                    </p>
                </div>
            </div>
            <Skills />
        </section>
    );
}

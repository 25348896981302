import React from 'react';
import './App.css';

import { AboutMe, Footer, Hackathons, Header, Projects } from './sections';
import Navbar from './components/Navbar';

function App() {
	return (
		<div className="App">
			<header className="App--header">
				<Header id="Header" />
				<Navbar id="Navbar" />
			</header>
			<AboutMe id="AboutMe" />
			<Projects id="Projects" />
			<Hackathons id="Hackathons" />
			<Footer id="Footer" />
		</div>
	);
}

export default App;

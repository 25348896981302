import * as React from 'react';
import StarCanva from '../../components/StarCanva/StarCanva';

export default function Header({ id }: { id: string }) {
    return (
        <div className='Header--container'>
            <StarCanva id={id} />
        </div>
    );
}

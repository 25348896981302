import React from 'react';

import './Footer.css';
import { GitHub as GitHubIcon, LinkedIn as LinkedInIcon, Email as EmailIcon } from '@mui/icons-material';

export default function Footer({ id }: { id: string }) {
    const socialLinks = [
        { icon: GitHubIcon, url: 'https://github.com/yourusername' },
        { icon: LinkedInIcon, url: 'https://www.linkedin.com/in/yourprofile' },
        { icon: EmailIcon, url: 'mailto:your.email@example.com' },
    ];

    return (
        <footer className='Footer--container' id={id}>
            <div className='Footer--content'>
                <p className='Footer--copyright'>© 2024 Svetoslav Iliev. All rights reserved.</p>
                <div className='Footer--social-links'>
                    {socialLinks.map((link, index) => (
                        <link.icon
                            key={index}
                            className='Footer--social-icon'
                            sx={{ fontSize: 36 }} /* Increased icon size */
                            onClick={() => window.open(link.url, '_blank')}
                        />
                    ))}
                </div>
            </div>
        </footer>
    );
}

import React from 'react';

import './Projects.css';
import images from '../../Assets/images/images';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

import {GitHub as GitHubIcon, Language as LanguageIcon} from '@mui/icons-material';

export default function Projects({ id }: { id: string }) {
    const projects = [
        {
            title: 'StereoMath',
            description: 'This is my personal portfolio. It is a website that showcases my skills and projects.',
            technologies: ['React Native', 'TypeScript', 'Python', 'React Native', 'React Native',],
            github: "https://github.com/SectorCT/StereoMath",
            link: "",
            images: [],
            headerImage: images['StereoMathHeader'],
        },
        {
            title: 'Portfolio',
            description: 'This is my personal portfolio. It is a website that showcases my skills and projects.',
            technologies: ['React', 'TypeScript', 'CSS'],
            github: "eqw",
            link: "",
            images: [images['Portfolio1'], images['Portfolio2'], images['Portfolio3']],
            headerImage: images['Portfolio1'],
        },
    ];


    return (
        <section className='Projects--container' id={id}>
            <h1 className='Projects--title'>Projects</h1>
            <div className='Projects--projects-container'>
                {projects.map((project, index) => {
                    return (
                        <div key={index} className='Projects--project-container'>
                            <img className='Projects--project-header-image' src={project.headerImage} alt={project.title} />
                            <div className='Projects--project-info'>
                                <h2 className='Projects--project-title'>{project.title}</h2>
                                <p className='Projects--project-description'>{project.description}</p>
                                <div className='Projects--project-technologies'>
                                    {project.technologies.map((technology, index) => {
                                        return (
                                            <p key={index} className='Projects--project-technology'>{technology}</p>
                                        )
                                    })}
                                </div>
                                <div className='Projects--project-links'>
                                    {project.github ? <GitHubIcon className='Projects--project-link' sx = {{ fontSize: 30}} onClick={() => window.open(`
                                        ${project.github}`, '_blank')} /> : null}
                                    {project.link ? <LanguageIcon className='Projects--project-link' sx = {{ fontSize: 30}} onClick={() => window.open(`
                                        ${project.link}`, '_blank')} /> : null}
                                    
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    );
}

const images: { [key: string]: any } = {
    TypeScript: require('./TypeScript.png'),
    JavaScript: require('./JavaScript.png'),
    ReactNative: require('./ReactNative.png'),
    React: require('./React.png'),
    "Node.js": require('./Node.js.png'),
    CSS: require('./CSS.png'),
    Python: require('./Python.png'),
    "C++": require('./C++.png'),
    C: require('./C.png'),
    git: require('./git.png'),
    Portfolio1: require('./Portfolio1.jpg'),
    Portfolio2: require('./Portfolio2.jpg'),
    Portfolio3: require('./Portfolio3.jpg'),
    StereoMathHeader: require('./StereoMath_header.png'),
}

export default images;
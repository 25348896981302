import React, { useEffect, useRef } from 'react';
import './StarCanva.css';

type Star = {
    x: number,
    y: number,
    radius: number,
    speedXBase: number
    speedX: number,
    speedYBase: number,
    speedY: number
}

export default function StarCanva({ id }: { id: string }) {
    let numberOfStars = useRef<number>(Math.floor(window.innerWidth / 12 - 0.5));
    const FPS = 60;
    const starDistanceForLine = 150;
    const minAndMaxSpeed = 60;

    const mouseRepulsionRadius = 50;


    let canvas = useRef<HTMLCanvasElement | null>(null);
    let ctx = useRef<CanvasRenderingContext2D | null>(null);

    let stars: Star[] = [];
    const mousePos = { x: 0, y: 0 };

    const drawStar = (starX: number, starY: number, radius: number) => {
        if (ctx.current) {
            ctx.current.globalCompositeOperation = "lighter";
            ctx.current.fillStyle = "#fff";
            ctx.current.beginPath();
            ctx.current.arc(starX, starY, radius, 0, 2 * Math.PI);
            ctx.current.fill();
            ctx.current.fillStyle = 'black';
            ctx.current.stroke();
        }
    }

    const drawStars = () => {
        if (ctx.current && canvas.current) {
            ctx.current.clearRect(0, 0, canvas.current.width, canvas.current.height);
            stars.forEach(star => {
                drawStar(star.x, star.y, star.radius);
                for (let i = 0; i < stars.length; i++) {
                    let star2 = stars[i];
                    let distance = Math.sqrt((star.x - star2.x) * (star.x - star2.x) + (star.y - star2.y) * (star.y - star2.y));
                    if (distance < starDistanceForLine) {
                        drawLinesBetweenStars(star.x, star.y, star2.x, star2.y, distance);
                    }
                }
            });
        }
    }

    const moveStars = () => {
        if (ctx.current && canvas.current) {
            stars.forEach(star => {
                star.x += star.speedX / FPS;
                star.y += star.speedY / FPS;

                if (canvas.current) {
                    if (Math.abs(mousePos.x - star.x) < mouseRepulsionRadius && Math.abs(mousePos.y - star.y) < mouseRepulsionRadius) {
                        const distanceBetweenMouseAndStar = Math.sqrt((mousePos.x - star.x) * (mousePos.x - star.x) + (mousePos.y - star.y) * (mousePos.y - star.y));
                        if (distanceBetweenMouseAndStar < mouseRepulsionRadius) {
                            star.speedX += (mousePos.x - star.x) * -2;
                            star.speedY += (mousePos.y - star.y) * -2;
                        }
                    }

                    if (Math.abs(star.speedX) > star.speedXBase) {
                        star.speedX *= 0.98;
                    }
                    if (Math.abs(star.speedY) > star.speedYBase) {
                        star.speedY *= 0.98;
                    }

                    if (Math.abs(star.speedX) < star.speedXBase) {
                        star.speedX = star.speedXBase * Math.sign(star.speedX);
                    }
                    if (Math.abs(star.speedY) < star.speedYBase) {
                        star.speedY = star.speedYBase * Math.sign(star.speedY);
                    }


                    if (star.x < 0 || star.x > canvas.current.width) {
                        if (star.x < 0) {
                            star.x = 0;
                        }
                        if (star.x > canvas.current.width) {
                            star.x = canvas.current.width;
                        }
                        star.speedX = -star.speedX;
                    }
                    if (star.y < 0 || star.y > canvas.current.height) {
                        if (star.y < 0) {
                            star.y = 0;
                        }
                        if (star.y > canvas.current.height) {
                            star.y = canvas.current.height;
                        }
                        star.speedY = -star.speedY;
                    }
                }
            });
        }
    }

    const drawLinesBetweenStars = (star1x: number, star1y: number, star2x: number, star2y: number, distance: number) => {
        if (ctx.current) {
            ctx.current.strokeStyle = `rgba(255,255,255,${1 - distance / starDistanceForLine})`;
            ctx.current.beginPath();
            ctx.current.moveTo(star1x, star1y);
            ctx.current.lineTo(star2x, star2y);
            ctx.current.stroke();
        }
    }

    const updateStars = () => {
        moveStars();
        drawStars();
        requestAnimationFrame(updateStars);
    }

    const addStar = () => {
        if (canvas.current) {
            let x = Math.random() * canvas.current.width;
            let y = Math.random() * canvas.current.height;
            let radius = Math.random() * 1 + 1;
            let speedX = Math.floor(Math.random() * minAndMaxSpeed * 2) - minAndMaxSpeed;
            let speedY = Math.floor(Math.random() * minAndMaxSpeed * 2) - minAndMaxSpeed;
            stars.push({ x, y, radius, speedXBase: Math.abs(speedX), speedX, speedYBase: Math.abs(speedY), speedY });
        }
    }

    const initStars = (width: number, height: number) => {
        stars = [];
        for (let i = 0; i < numberOfStars.current; i++) {
            addStar();
        }
    }

    useEffect(() => {
        const mouseMove = (e: MouseEvent) => {
            if (canvas.current) {
                mousePos.x = e.clientX + window.scrollX - canvas.current.offsetLeft;
                mousePos.y = e.clientY + window.scrollY - canvas.current.offsetTop;
            }
        }
        window.addEventListener('mousemove', mouseMove);
        return () => {
            window.removeEventListener('mousemove', mouseMove);
        }
    }, []);

    useEffect(() => {
        const resize = () => {
            if (canvas.current) {
                canvas.current.width = window.innerWidth;
                const newNumberOfStars = Math.floor(canvas.current.width / 12);
                if (newNumberOfStars > numberOfStars.current) {
                    for (let i = 0; i < newNumberOfStars - numberOfStars.current; i++) {
                        addStar();
                    }
                } else if (newNumberOfStars < numberOfStars.current) {
                    for (let i = 0; i < numberOfStars.current - newNumberOfStars; i++) {
                        stars.pop();
                    }
                }
                numberOfStars.current = newNumberOfStars;


                canvas.current.height = window.innerHeight;
            }
        }
        window.addEventListener('resize', resize);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, [canvas.current]);


    useEffect(() => {
        canvas.current = document.getElementById('starCanva') as HTMLCanvasElement;
        canvas.current.width = window.innerWidth;
        canvas.current.height = window.innerHeight;
        ctx.current = canvas.current.getContext('2d');
        initStars(canvas.current.width, canvas.current.height);
        updateStars();
    }, []);

    return (
        <section className='StarCanva--container' id={id}>
            <canvas id="starCanva" className='canvas'>
            </canvas>
            <h1 className='text'>
                Hello, I'm 
                <br /><span className='name'> Svetoslav Iliev</span> <br /> Front-End web developer</h1>
        </section>
    );
}


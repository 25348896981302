import React, { useState, useEffect } from 'react';

import './Hackathons.css';
import images from '../../Assets/images/images';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Pagination, Navigation } from 'swiper/modules';

export default function Hackathons({ id }: { id: string }) {

    const [projects, setProjects] = useState([
        {
            title: 'HackTues 8',
            description: 'HackTues is a hackathon hosted by my school. It is a 3-day event where students can work on projects and compete.',
            link: '',
            images: [],
            headerImage: images['StereoMathHeader'],
            date: '2021-05-01',
        },
        {
            title: 'HackTues 9',
            description: 'HackTues is a hackathon hosted by my school. It is a 3-day event where students can work on projects and compete.',
            link: '',
            images: [],
            headerImage: images['Portfolio1'],
            date: '2022-05-01',
        },
        {
            title: 'HackTues 10',
            description: 'HackTues is a hackathon hosted by my school. It is a 3-day event where students can work on projects and compete.',
            link: '',
            images: [],
            headerImage: images['Portfolio1'],
            date: '2023-05-01',
        },
        {
            title: 'Teenovator 6',
            description: 'Teenovator is a program in my country for teenagers to learn about entrepreneurship and business.',
            link: '',
            images: [],
            headerImage: '', // Add an appropriate image if needed
            date: '2024-05-01',
        },
    ]);

    const [numberOfProjects, setNumberOfProjects] = useState(3); 

    useEffect(() => {
        const handleResize = () => {
            setNumberOfProjects(window.innerWidth > 768 ? Math.floor(window.innerWidth / 300) : 1);

        };

        handleResize(); // Set initial value
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <section className='Hackathons--container' id={id}>
            <h1 className='Hackathons--title'>Hackathons</h1>
            <div className='Hackathons--slider-container'>
                <Swiper
                    pagination={{ clickable: true }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="Hackathons--swiper"
                    centeredSlides={projects.length > numberOfProjects ? true : false}
                    slidesPerView={window.innerWidth > 768 ? numberOfProjects : 1}
                    loop={projects.length >= numberOfProjects ? true : false}
                    speed={400}
                >
                    {projects.sort((a, b) => {
                        const dateA = new Date(a.date);
                        const dateB = new Date(b.date);
                        if (projects.length > numberOfProjects) {
                            return dateB.getTime() - dateA.getTime();
                        }
                        return 0;
                    }).map((project: any, index: number) => (
                        <SwiperSlide key={index} className='Hackathons--swiper-slide'>
                            <div className='Hackathons--project-container'>
                                {project.headerImage && (
                                    <img className='Hackathons--project-header-image' src={project.headerImage} alt={project.title} />
                                )}
                                <div className='Hackathons--project-info'>
                                    <h2 className='Hackathons--project-title'>{project.title}</h2>
                                    <p className='Hackathons--project-description'>{project.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}
